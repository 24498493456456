import React from 'react'
import { Modal } from 'antd'

class Mapper extends React.Component{

    state = {
        formData:{},
        title:'选择地址',
        visible:false,
        modalLoading:false,
        callback:null

    }

    componentDidMount() {
        this.props.onRef(this)
        this.addListener()
    }
    addListener = () => {
        window.addEventListener("message",  (event) =>{
            var loc = event.data;
            if (loc && loc.module == 'locationPicker') {
                let formData = {};
                formData.lat = loc.latlng.lat + "";
                formData.lng = loc.latlng.lng + "";
                formData.address = loc.poiaddress + "" + loc.poiname;
                formData.map_address = JSON.stringify(loc);
                this.setState({
                    formData
                })
            }
        });
    }
    componentWillUnmount() {
        this.setState = () => {
            return ;
        }
    }

    show = (callback) => {
        this.setState({
            visible:true
        })
        if(typeof callback == 'function'){
            this.setState({
                callback
            })
        }
    }
    close = () => {
        this.setState({
            visible:false
        })
    }
    handleOk = () => {
        let formData = this.state.formData;
        if(this.state.callback){
            this.state.callback(formData)
            this.close()
        }
    }
    handleCancel = () => {
        this.close()
    }


    render() {
        return(
            <Modal
                title={this.state.title}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
                width={600}
                centered={true}
            >
                <div className={'txtCenter'} >
                    <iframe
                        title={'选择位置'}
                        id="mapPage"
                        style={{width:'550px',height:'550px'}}
                        frameBorder={0}
                        src={`https://apis.map.qq.com/tools/locpicker?search=1&type=1&key=2NJBZ-RU7E2-RUJU5-CWQZ5-C7HFT-FQFVD&referer=澄瑄科技`}
                    >
                    </iframe>
                </div>
            </Modal>

        )
    }


}
export default Mapper

import $axios from '@/axios/axios.js'

export function adminDetail(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/admin/detail`,
        method:'post',
        data
    })
}

export function adminComplete(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/admin/complete`,
        method:'post',
        data
    })
}

export function modifyPwd(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/admin/modifyPassword`,
        method:'post',
        data
    })
}

export function sendSms(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/send`,
        method:'post',
        data
    })
}

import React from 'react'
import { Modal, Form } from 'antd'
import { withRouter, Link } from 'react-router-dom'
import CX from '@/utils/CX'
import message from '@/utils/message'
import './shareModal.scss'

class ShareModal extends React.Component{
    state = {
        title:'推广',
        visible:false,
        modalLoading:false,
        callback:null,
        poster:'',
        wxUrl:'',
        h5Url:''
    }
    componentDidMount() {
        this.props.onRef(this)
    }
    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.poster || nextProps.path){
            this.setState({
                poster:nextProps.poster || '',
                wxUrl:nextProps.path || ''
            })
        }
    }

    show = (callback) => {
        this.setState({
            visible:true,
            callback: callback || null
        })
    }
    close = () => {
        this.setState({
            visible:false
        })
    }
    handleOk = () => {
        let callback = this.state.callback;
        if(typeof callback == 'function'){
            callback()
        }
        this.close()
    }
    handleCancel = () => {
        this.close()
    }
    handleCopy = (text) => {
        CX.copyTxt(text);
        message.success('复制成功')
    }
    render() {
        return (
            <Modal
                title={this.state.title}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={500}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
                centered={true}
            >
                <div className={`txtCenter`}>
                    <div className={`qr-img`}>
                        <img src={ this.state.poster } alt="" width={200} />
                    </div>
                    <div className={'share-qr-txt flex align-center justify-center'}>
                        <span className={`lh25 txt-elips`}>扫一扫或</span>
                        <a href={`${this.state.poster}&download=1`} target={`_blank`} className={`fi-link mgl10 lh25`}>下载二维码</a>
                    </div>
                    <div className={'share-qr-txt'}>
                        <span className={`lh25 txt-elips`}>小程序路径：{ this.state.wxUrl }</span>
                        <span className={`fi-link mgl10 lh25`} onClick={ () => { this.handleCopy(this.state.wxUrl) } }>复制</span>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default Form.create({})(withRouter(ShareModal))

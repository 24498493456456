import Dashboard from '@/views/dashboard/index'
import ShopLists from '@/views/shop/lists'
import ShopAdd from '@/views/shop/add'
import ShopAuth from '@/views/shop/shop-auth'
import MiniProgramLists from '@/views/miniProgram/lists'
import VersionLists from '@/views/version/lists'
import VersionAdd from '@/views/version/add'

import ErrorPage from '@/views/error/index'

export const routes = [
    { path:'/dashboard', component:Dashboard, withoutSideMenu:true },
    { path:'/shop/lists', component:ShopLists },
    { path:'/shop/add/:id?', component:ShopAdd },
    { path:'/shop/auth/:id?', component:ShopAuth },
    { path:'/miniProgram/lists', component:MiniProgramLists },
    { path:'/version/lists', component:VersionLists },
    { path:'/version/add/:id?', component:VersionAdd },

    { path:'/error/404',component:ErrorPage },
]

import axios from 'axios'
import { message } from 'antd'
 import history from '@/utils/history'
import store from '@/redux/store'
import CX from '@/utils/CX'
import { removeAdmin } from '@/redux/actions/admin'
import { removeShop } from '@/redux/actions/shop'
import Cookie from 'js-cookie'

const $axios = axios.create({
    baseURL:'',
    timeout:30000,
    retry:4,
    retryDelay:1000
})

//请求拦截
$axios.interceptors.request.use(
    config => {
        if(config.params){
            config.params.api_token = Cookie.get('token') || ``;
        }
        if(config.data){
            config.data.api_token = Cookie.get('token') || ``;
        }
        config.method = config.method.toUpperCase()
        config.url = `${config.url}?t=${CX.genUUID()}`
        return config
    },
    error => {
        message.error(error,2)
        return Promise.reject(error)
    }
)

//响应拦截
$axios.interceptors.response.use(
    response => {
        //响应数据处理
        if(response.data.code == 0){
            message.error(response.data.msg)
        }
        if(response.data.code == -1){
            message.error(response.data.msg)
            Cookie.remove('token')
            store.dispatch(removeAdmin())
            store.dispatch(removeShop())
            setTimeout(() => {
                history.push('/login')
            },1500)
        }
        return response.data
    },
    error => {
        message.error(error.message,2)
        return Promise.reject(error)
    }
)
export default $axios




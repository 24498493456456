import $axios from '@/axios/axios.js'


export function imgLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/file/list`,
        method:'post',
        data
    })
}

export function delImg(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/file/delete`,
        method:'post',
        data
    })
}

export function uploadImg(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/file/uploadImg`,
        method:'post',
        headers:{'Content-Type':'multipart/form-data'},
        data,
    })
}
export function addCategory(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/file-category/create`,
        method:'post',
        data
    })
}



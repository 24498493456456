import $axios from '@/axios/axios.js'

export function shopLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/acp/shop`,
        method:'post',
        data
    })
}

export function shopCreate(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/acp/shop/create`,
        method:'post',
        data
    })
}

export function shopDetail(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/acp/shop/detail`,
        method:'post',
        data
    })
}

export function shopEdit(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/acp/shop/save`,
        method:'post',
        data
    })
}

export function shopAddAuth(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/acp/shop/addAuth`,
        method:'post',
        data
    })
}

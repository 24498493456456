import React, { Component } from 'react'
import { Button, Input, Checkbox } from 'antd'
import { withRouter } from 'react-router-dom'
import message from '@/utils/message'
import { setAdmin } from '@/redux/actions/admin'
import { setShop } from '@/redux/actions/shop'
import { connect } from 'react-redux'
import { login, getCaptcha  } from '@/api/login'
import Cookie from 'js-cookie'
import CX from '@/utils/CX'
import '@/assets/css/login.scss'

class Login extends Component {
    state = {
        isLoading:false,
        verifyImg:'',
        mobile:'',
        password:'',
        code:'',
        imageKey:'',
        pwdType:'text',
    }
    componentDidMount() {
        this.updateVerify()
        this.setState({
            pwdType:'password'
        })
    }

    updateVerify = () => {
        let sendData = {}
        getCaptcha(sendData)
            .then(res => {
                console.log(res)
                if(res.code == 1){
                    let imageKey = res.data.key,
                        verifyImg = res.data.img;
                    this.setState({
                        imageKey, verifyImg
                    })
                }
            })
    }
    login = e => {
        let sendData = {
            mobile:this.state.mobile,
            password:this.state.password,
            img_code:this.state.code,
            _k:this.state.imageKey
        }
        this.setState({
            isLoading:true
        })
        login(sendData)
            .then(res => {
                if(res.code == 1){
                    let admin = res.data.admin,
                        token = admin.api_token || ``;
                    Cookie.set('token',token);
                    this.props.setAdmin(admin);
                    message.success('登录成功')
                        .then(() => {
                            this.props.history.push({
                                pathname:`/shop/lists`
                            })
                        })

                }else {
                    this.setState({
                        isLoading:false
                    })
                    this.updateVerify()
                }
            })
            .catch(err => {
                this.updateVerify()
            })

    }
    getInputValue = e => {
       let key = e.currentTarget.dataset.name,
           value = e.target.value,
           datas = {};
       datas[key] = value;
       this.setState(datas)
    }
    handleChangeType = () => {
        let pwdType = this.state.pwdType;
        if(pwdType == 'text'){
            pwdType = 'password'
        }else {
            pwdType = 'text'
        }
        this.setState({
            pwdType
        })
    }
    handleLink = e => {
        let link = e.currentTarget.dataset.link || ``;
        link && this.props.history.push({
            pathname:link
        })
    }
    render() {
        return(
            <div className={`cx-common-login`}>
                <div className={`login-logo`}>
                    <img src={`https://img.cxkoo.com/chengxuan/3/2021-10-02/6c976d5177d68923174d78eb67d9ccc0.png`} alt=""/>
                </div>
                <div className={`cx-login-slogan`}>
                    <div className={`txt1`}>澄瑄科技ACP后台</div>

                </div>
                <div className={`cx-login-wrap`}>
                    <div className={`cx-login-inner`}>
                        <div className={`title`}>ACP登录</div>
                        <div className={`form-cont`}>
                            <div className={`form-item`}>
                                <div className={`fi-name`}>登录账号</div>
                                <div className={`form-control`}>
                                    <Input
                                        type={`text`}
                                        placeholder={`输入手机号`}
                                        className={`form-input`}
                                        data-name={'mobile'}
                                        onChange={this.getInputValue}
                                        onPressEnter={this.login}
                                    />
                                </div>
                            </div>
                            <div className={`form-item`}>
                                <div className={`fi-name`}>登录密码</div>
                                <div className={`form-control`}>
                                    <Input
                                        type={`${ this.state.pwdType }`}
                                        placeholder={`输入密码`}
                                        className={`form-input`}
                                        data-name={'password'}
                                        onChange={this.getInputValue}
                                        onPressEnter={this.login}
                                    />
                                    <div className={`input-type-img mgl5`} onClick={ this.handleChangeType }>
                                        {
                                            this.state.pwdType == 'password'
                                                ?
                                                <img src={`https://img.cxkoo.com/chengxuan/3/2021-10-02/e14f3dd41c6c0dd02f38b6145b737c20.png`} className={`eye-close`} alt=""/>
                                                :
                                                <img src={`https://img.cxkoo.com/chengxuan/3/2021-10-02/3ea5c81a33106884ba67f8a35eaf7079.png`} className={`eye-open`} alt=""/>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={`form-item`}>
                                <div className={`fi-name`}>安全验证</div>
                                <div className={`form-control`}>
                                    <Input
                                        type={`text`}
                                        placeholder={`输入验证码`}
                                        className={`form-input`}
                                        data-name={'code'}
                                        onChange={this.getInputValue}
                                        onPressEnter={this.login}
                                    />
                                    <img src={this.state.verifyImg} alt="" className={'verify'} onClick={this.updateVerify} />
                                </div>
                            </div>
                            <div className={`form-btn-group`}>
                                <Button type={`primary`} className={`btn-login`} onClick={this.login} loading={this.state.isLoading} >登录</Button>
                            </div>
                        </div>
                        <div className={`form-actions`}>
                            <div className={`left`}>
                                <Checkbox defaultChecked className={`login-check`}>3天内自动登录</Checkbox>
                            </div>
                            <div className={`right`}>
                                {/*<div className={`txt`} onClick={ this.handleLink } data-link="/forget">忘记密码</div>*/}
                                {/*<div className={`txt`} onClick={ this.handleLink } data-link="/register">免费注册</div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    setAdmin: data => {
      dispatch(setAdmin(data))
    },
    setShop: data => {
        dispatch(setShop(data))
    }
})

export default connect(mapStateToProps,mapDispatchToProps)( withRouter(Login))

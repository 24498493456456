import React from 'react'
import { Form, Input, Tree, Checkbox , Button, Select, DatePicker, Modal } from 'antd'
import { withRouter } from 'react-router-dom'
import CX from '@/utils/CX'
import '@/assets/css/store/store.scss'
import Gallery from '@/components/Gallery/Gallery'
import Mapper from '@/components/Mapper/Mapper'
import {  shopDetail, shopAddAuth } from '@/api/shop'
import { getAllMenu } from '@/api/menu'
import message from '@/utils/message'
import Loading from '@/components/Loading'
const FormItem = Form.Item
const { TreeNode } = Tree
class ShopAuth extends React.Component {

    state = {
        formData: '',
        menus:[],
        expandedKeys:[],
        autoExpandParent:true,
        checkedKeys:[],
        halfCheckedKeys:[],
        isAll:false
    }

    componentDidMount() {
        this.fetchMenu()
            .then(() => {
                this.fetchData()
            })
    }

    fetchData = (params = {}) => {
        let sendData = {}
        if(this.props.match.params.id){
            this.setState({
                isLoading:true
            })
            sendData.id = this.props.match.params.id
            shopDetail(sendData)
                .then(res => {
                    if(res.code == 1){
                        let detail = res.data.detail,
                            menus = this.state.menus,
                            halfCheckedKeys = detail.half_checked_keys || [],
                            rules = detail.add_auths || [],
                            checkedKeys = [];
                        checkedKeys = rules.filter(value => halfCheckedKeys.indexOf(value) == -1);

                        menus=menus.map(item=>{
                            if(item.subMenu){
                                item.subMenu=item.subMenu.filter(sub=>!sub.hide)
                                
                                item.subMenu = item.subMenu.map(sub=>{
                                    if(sub.children){
                                        sub.children=sub.children.filter(child=>!child.hide)
                                    }
                                    return sub
                                })
                                
                            }
                            return item
                        })
                        
                        menus.forEach((item,index) => {
                            item.subMenu && item.subMenu.forEach((sub,subIndex) => {
                                if(checkedKeys.indexOf(sub.menu_id) == -1 && !halfCheckedKeys.includes(item.menu_id)){
                                    halfCheckedKeys.push(item.menu_id)
                                }
                                sub.children && sub.children.forEach((child,childKey) => {
                                    if(checkedKeys.indexOf(child.menu_id) == -1 && !halfCheckedKeys.includes(sub.menu_id)){
                                        halfCheckedKeys.push(sub.menu_id)
                                        if(!halfCheckedKeys.includes(item.menu_id)){
                                            halfCheckedKeys.push(item.menu_id)
                                        }
                                    }
                                })
                            })
                        })
                        checkedKeys = rules.filter(value => halfCheckedKeys.indexOf(value) == -1);
                        this.setState({
                            formData:detail,
                            checkedKeys,
                            halfCheckedKeys
                        })
                    }
                })
                .finally(() => {
                    this.setState({
                        isLoading:false
                    })
                })
        }
    }
    fetchMenu = () => {
        let sendData = {}
        this.setState({
            isLoading:true
        })
        return new Promise((resolve, reject) => {
            getAllMenu(sendData)
                .then(res => {
                    if(res.code == 1){
                        let menus = res.data.list
                        this.setState({
                            menus
                        },() => {
                            resolve()
                        })
                    }else {
                        reject()
                    }
                })
                .finally(() => {
                    this.setState({
                        isLoading:false
                    })
                })
        })

    }
    saveData = () => {
        let sendData = {},
            formData = this.state.formData || {},
            checkedKeys = this.state.checkedKeys,
            halfCheckedKeys = this.state.halfCheckedKeys;
        this.props.form.validateFieldsAndScroll((err,values) => {
            if(!err){
                sendData.id = formData.id || ''
                sendData.half_checked_keys = halfCheckedKeys;
                sendData.auth = CX.diff(halfCheckedKeys,checkedKeys)
                this.setState({
                    isLoading:true
                })
                shopAddAuth(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('编辑成功')
                                .then(() => {
                                    this.props.history.push({
                                        pathname:`/shop/lists`
                                    })
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })
            }
        })
    }
    onExpand = (expandedKeys) => {
        this.setState({
            expandedKeys
        })
    }
    onCheck = (checkedKeys,e) => {
        this.setState({
            checkedKeys,
            halfCheckedKeys:e.halfCheckedKeys
        })
    }
    handleAll = (e) => {
        let checkedKeys = [],
            allMenu = this.state.menus,
            val = e.target.checked;
        if(val){
            //全选
            allMenu.map((item) => {
                checkedKeys.push(item.menu_id);
                item.subMenu && item.subMenu.map((sub) => {
                    checkedKeys.push(sub.menu_id);
                    sub.children && sub.children.map((child) => {
                        checkedKeys.push(child.menu_id)
                    })
                })
            })
        }else {
            //反选
            checkedKeys = []
        }
        this.setState({
            isAll:val,
            halfCheckedKeys:[],
            checkedKeys
        })
    }



    render() {
        let data = this.state.formData || {};
        let menus = this.state.menus || [];
        const {getFieldDecorator, getFieldValue} = this.props.form
        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 10
            }
        }
        return (
            <div className={`shadow-radius`}>
                <Gallery onRef={e => {this.Gallery = e}}></Gallery>
                <Mapper onRef={e => { this.Mapper = e }} lat={data.lat || ''} lng={data.lng || ''} address={data.address || ''}></Mapper>
                <Form {...formItemLayout} className={'model-form'}>
                    <FormItem label={'商家名称'}>
                        <p>{ data.name || '' }</p>
                    </FormItem>
                    <FormItem label={'设置权限'}>
                        <div>
                            <Tree
                                checkable
                                onExpand={this.onExpand}
                                expandedKeys={this.state.expandedKeys}
                                autoExpandParent={this.state.autoExpandParent}
                                onCheck={this.onCheck}
                                checkedKeys={this.state.checkedKeys}
                                checkStrictly={false}
                            >
                                {
                                    menus.map((item,index) => (
                                        <TreeNode title={item.title} key={item.menu_id}>
                                            {
                                                item.subMenu && item.subMenu.map((sub,subIndex) => (
                                                    <TreeNode title={sub.title} key={sub.menu_id}>
                                                        {
                                                            sub.children && sub.children.map((child,childKey) => {
                                                                return(
                                                                    <TreeNode title={child.title} key={child.menu_id}></TreeNode>
                                                                )
                                                            })
                                                        }
                                                    </TreeNode>
                                                ))
                                            }
                                        </TreeNode>
                                    ))
                                }
                            </Tree>
                        </div>
                        <div>
                            <Checkbox checked={ this.state.isAll } onChange={this.handleAll}>全选</Checkbox>
                        </div>
                    </FormItem>
                    <FormItem label={` `} colon={false}>
                        <Button type={'primary'} onClick={this.saveData}>立即保存</Button>
                    </FormItem>
                </Form>
                {
                    this.state.isLoading
                        ?
                        <Loading/>
                        :
                        null
                }
            </div>
        )

    }
}
export default Form.create({})(withRouter(ShopAuth))



import $axios from '@/axios/axios.js'

export function storeLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/store/list`,
        method:'post',
        data
    })
}

export function addStore(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/store/create`,
        method:'post',
        data
    })
}

export function storeDetail(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/store/detail`,
        method:'post',
        data
    })
}

export function storeEdit(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/store/edit`,
        method:'post',
        data
    })
}

export function storeDelete(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/store/delete`,
        method:'post',
        data
    })
}

export function storeStatus(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/store/setStatus`,
        method:'post',
        data
    })
}

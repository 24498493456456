import React, { Component } from 'react'
import { Button, Input } from 'antd'
import { withRouter } from 'react-router-dom'
import message from '@/utils/message'
import { connect } from 'react-redux'
import { getCaptcha, register, sendSmsCode } from '@/api/login'
import '@/assets/css/login.scss'

class Register extends Component {
    state = {
        isLoading:false,
        verifyImg:'',
        mobile:'',
        password:'',
        code:'',
        imageKey:'',
        activeKey:2,
        seconds: 60, //倒计时
        isSendSms:false,
        sms_code:'', //短信验证码
        _cx:'',
        name:'',
        pwdType:'text',

    }
    componentDidMount() {
        this.updateVerify()
        this.setState({
            pwdType:'password'
        })
    }

    updateVerify = () => {
        let sendData = {}
        getCaptcha(sendData)
            .then(res => {
                console.log(res)
                if(res.code == 1){
                    let imageKey = res.data.key,
                        verifyImg = res.data.img;
                    this.setState({
                        imageKey, verifyImg
                    })
                }
            })
    }
    sendSms = () => {
        let sendData = {}
        sendData.mobile = this.state.mobile;
        sendData.img_code = this.state.code;
        sendData._k = this.state.imageKey;
        sendSmsCode(sendData)
            .then(res => {
                if(res.code == 1){
                    message.success('短信验证码已发送');
                    this.setState({
                        isSendSms:true,
                        _cx:res.data._cx || ''
                    })
                    this.countDown()
                }
            })


    }
    countDown = () => {
        let seconds = this.state.seconds;
        if(seconds <= 0){
            this.setState({
                seconds:60,
                isSendSms:false
            })
            return false;

        }else {
            this.setState({
                seconds:this.state.seconds-1
            })
            setTimeout(() => {
                this.countDown();
            },1000)

        }
    }
    register = () => {
        let sendData = {};
        sendData.mobile = this.state.mobile;
        sendData.password = this.state.password;
        sendData.sms_code = this.state.sms_code;
        sendData._cx = this.state._cx;
        sendData.name = this.state.name;
        this.setState({
            isLoading:true
        })
        register(sendData)
            .then(res => {
                if(res.code == 1){
                    message.success('账号注册成功')
                        .then(() => {
                            this.props.history.push({
                                pathname:`/login`
                            })
                        })

                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })


    }
    getInputValue = e => {
        let key = e.currentTarget.dataset.name,
            value = e.target.value,
            datas = {};
        datas[key] = value;
        this.setState(datas)
    }
    handleChangeType = () => {
        let pwdType = this.state.pwdType;
        if(pwdType == 'text'){
            pwdType = 'password'
        }else {
            pwdType = 'text'
        }
        this.setState({
            pwdType
        })
    }
    handleLink = e => {
        let link = e.currentTarget.dataset.link || ``;
        link && this.props.history.push({
            pathname:link
        })
    }
    render() {
        return(
            <div className={`cx-common-login`}>
                <div className={`login-logo`}>
                    <img src={`https://img.cxkoo.com/chengxuan/3/2021-10-02/6c976d5177d68923174d78eb67d9ccc0.png`} alt=""/>
                </div>
                <div className={`cx-login-slogan`}>
                    <div className={`txt1`}>开店就用澄瑄客</div>
                    <div className={`txt2`}>
                        打造最合适您的<span className={`txt-color mgl5`}>SaaS&nbsp;+&nbsp;运营方案</span>
                    </div>
                </div>
                <div className={`cx-login-wrap register`}>
                    <div className={`cx-login-inner`}>
                        <div className={`title`}>用户注册</div>
                        <div className={`form-cont`}>
                            <div className={`form-item`}>
                                <div className={`fi-name`}>手机号码</div>
                                <div className={`form-control`}>
                                    <Input
                                        type={`text`}
                                        placeholder={`输入手机号`}
                                        className={`form-input`}
                                        data-name={'mobile'}
                                        onChange={this.getInputValue}
                                        onPressEnter={this.register}
                                        autoComplete={'new-password'}
                                    />
                                </div>
                            </div>
                            <div className={`form-item`}>
                                <div className={`fi-name`}>安全验证</div>
                                <div className={`form-control`}>
                                    <Input
                                        type={`text`}
                                        placeholder={`输入验证码`}
                                        className={`form-input`}
                                        data-name={'code'}
                                        onChange={this.getInputValue}
                                        onPressEnter={this.register}
                                        autoComplete={'new-password'}
                                        maxLength={6}
                                    />
                                    <img src={this.state.verifyImg} alt="" className={'verify'} onClick={this.updateVerify} />
                                </div>
                            </div>
                            <div className={`form-item`}>
                                <div className={`fi-name`}>短信验证</div>
                                <div className={`form-control`}>
                                    <Input
                                        type={`text`}
                                        placeholder={`输入短信验证码`}
                                        className={`form-input`}
                                        data-name={'sms_code'}
                                        onChange={this.getInputValue}
                                        onPressEnter={this.register}
                                    />
                                    <Button type={'primary'} stye={{width:'100px'}} onClick={this.sendSms} disabled={this.state.isSendSms} className={`btn-sms`}>
                                        {
                                            this.state.isSendSms
                                                ?
                                                `重新发送${this.state.seconds}秒`
                                                :
                                                '获取验证码'
                                        }
                                    </Button>
                                </div>
                            </div>
                            <div className={`form-item`}>
                                <div className={`fi-name`}>设置密码</div>
                                <div className={`form-control`}>
                                    <Input
                                        type={`${ this.state.pwdType }`}
                                        placeholder={`请设置密码，6-21位数字与字母结合`}
                                        className={`form-input`}
                                        data-name={'password'}
                                        onChange={this.getInputValue}
                                        onPressEnter={this.register}
                                    />
                                    <div className={`input-type-img mgl5`} onClick={ this.handleChangeType }>
                                        {
                                            this.state.pwdType == 'password'
                                                ?
                                                <img src={`https://img.cxkoo.com/chengxuan/3/2021-10-02/e14f3dd41c6c0dd02f38b6145b737c20.png`} className={`eye-close`} alt=""/>
                                                :
                                                <img src={`https://img.cxkoo.com/chengxuan/3/2021-10-02/3ea5c81a33106884ba67f8a35eaf7079.png`} className={`eye-open`} alt=""/>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className={`form-btn-group`}>
                                <Button type={`primary`} className={`btn-login`} onClick={this.register} loading={this.state.isLoading} >注册即获得7天免费体验</Button>
                            </div>
                        </div>
                        <div className={`form-actions`}>
                            <div className={`left`}>
                            </div>
                            <div className={`right`}>
                                <div className={`tip-txt`}>已有账号？</div>
                                <div className={`txt`} onClick={ this.handleLink } data-link="/login">立即登录</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps,mapDispatchToProps)( withRouter(Register))

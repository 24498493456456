import React from 'react'
import { Form, Input, Button, Table, Pagination, Tag, Modal, Divider, Select } from 'antd'
import { withRouter } from 'react-router-dom'
import { storeStatus, storeDelete } from '@/api/store'
import { versionLists } from '@/api/version'
import message from '@/utils/message'
import Loading from '@/components/Loading'
import ShareModal from '@/components/shareModal/shareModal'
const FormItem = Form.Item;
class VersionLists extends React.Component{
    state = {
        columns:[
            {
                title:'版本名称',
                dataIndex:'name',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.name || '--'}</p>
                    )
                }
            },
            {
                title:'商家数量',
                dataIndex:'shop_count',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.shop_count || '--'}</p>
                    )
                }
            },
            {
                title:'门店数量',
                dataIndex:'store_num',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.store_num || '-'}</p>
                    )
                }
            },
            {
                title:'创建时间',
                dataIndex:'created_at',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.created_at}</p>
                    )
                }
            },
            {
                title:'操作',
                dataIndex:'opts',
                width:'25%',
                render:(text, rowData, index) => {
                    return (
                        <div className={'btn-row'}>
                            <Button type={"link"} size={"small"} onClick={() => {this.handleEdit(rowData)}}>编辑</Button>
                        </div>
                    )
                }
            },
        ],
        searchForm:{
            name:'',
        },
        tableData:[],
        tableLoading:false,
        isLoading:false,
        page:1,
        total:0,
        per_page:10,
        poster:'',
        path:''

    }

    componentDidMount() {
        this.fetchData()
    }
    fetchData = () => {
        let sendData = {}
        console.log(sendData)
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        this.setState({
            tableLoading:true
        })
        versionLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.list;
                    this.setState({
                        tableData:lists
                    })

                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }
    search = () => {
        this.props.form.validateFields((err,values) => {
            if(!err){
                let searchForm = values;
                this.setState({
                    searchForm,
                    page:1
                },() => {
                    this.fetchData();
                })
            }
        })
    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    handleAdd = () => {
        this.props.history.push({
            pathname:`/version/add`
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for(var i in searchForm){
            searchForm[i] = ''
        }
        this.setState({
            searchForm,
            page:1
        },() => {
            this.fetchData()
        })

    }
    handleEdit = rowData => {
        this.props.history.push({
            pathname:`/version/add/${rowData.id}`
        })
    }
    handleStatus = (rowData,status) => {
        let sendData = {}
        sendData.ids = [rowData.id];
        sendData.status = status;
        Modal.confirm({
            title:`是否将门店状态设置为${rowData.status == 1 ? '歇业' : '营业'}`,
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                storeStatus(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }

                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })
            }
        })
    }
    handleDelete = (rowData) => {
        let sendData = {}
        sendData.id = rowData.id || ``;
        Modal.confirm({
            title:`是否删除该门店？该操作不可逆，请谨慎操作`,
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                storeDelete(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }

                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })
            }
        })

    }
    handleShare = row => {
        this.setState({
            poster:row.code_url,
            path:`/pages/index/index?store_id=${row.id}`
        })
        this.ShareModal.show()
    }
    render() {
        const rowSelection = {
            onChange: (selectedRowKeys,selectedRows) => {
                this.setState({
                    selectRows:selectedRows,
                    selectedRowKeys
                })
            }
        }
        const { getFieldDecorator } = this.props.form
        return(
            <div className={'shadow-radius'}>
                <ShareModal onRef={e => { this.ShareModal = e }} poster={ this.state.poster } path={ this.state.path } ></ShareModal>
                <div className={'content-head mgb15'}>
                    <Form className={'search-form clearfix'}>
                        <FormItem label={'版本名称'} className={'fl'}>
                            {
                                getFieldDecorator('name',{
                                    initialValue: ''
                                })(
                                    <Input placeholder='搜索版本名称'/>
                                )
                            }
                        </FormItem>
                        <FormItem className={'mgl20 fl'}>
                            <Button type='primary'  onClick={() => this.search()}>搜索</Button>
                            <Button type={"default"} className={'mgl10'} onClick={() => {this.handleReset()}}>重置</Button>
                        </FormItem>
                    </Form>
                </div>
                <div className={'mgt10'}>
                    <Button type={'primary'} onClick={this.handleAdd} icon={'plus'}>新增版本</Button>
                </div>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                { this.state.isLoading ? <Loading /> : null }
            </div>
        )
    }

}
export default Form.create({})(withRouter(VersionLists))

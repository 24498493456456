import React from 'react'
import { Form, Input, Radio, TimePicker, Button, Select, DatePicker, Modal } from 'antd'
import { withRouter } from 'react-router-dom'
import moment from 'moment';
import '@/assets/css/store/store.scss'
import Gallery from '@/components/Gallery/Gallery'
import PictureCard from '@/components/PictureCard/PictureCard'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
import Mapper from '@/components/Mapper/Mapper'
import PropTypes from "prop-types";
import { addStore, storeDetail, storeEdit } from '@/api/store'
import { versionLists } from '@/api/version'
import { shopCreate, shopDetail, shopEdit } from '@/api/shop'
import message from '@/utils/message'
const FormItem = Form.Item
class EditForm extends React.Component {
    static contextTypes = {
        loading: PropTypes.object,
        setLoading: PropTypes.func
    }

    state = {
        formData: '',
        allVersion:[]
    }

    componentDidMount() {
        this.props.onRef(this)
        this.fetchData()
        this.fetchVersion()
    }

    fetchData = (params = {}) => {
        let sendData = {}
        if(this.props.match.params.id){
            this.context.setLoading(true)
            sendData.id = this.props.match.params.id
            shopDetail(sendData)
                .then(res => {
                    if(res.code == 1){
                        let detail = res.data.detail;
                        detail.mobile = detail.admin?.mobile || '';
                        this.setState({
                            formData:detail
                        })
                    }
                })
                .finally(() => {
                    this.context.setLoading(false)
                })
        }

    }
    fetchVersion = () => {
        let sendData = {}
        this.context.setLoading(true)
        versionLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.list;
                    this.setState({
                        allVersion:lists
                    })
                }
            })
            .finally(() => {
                this.context.setLoading(false)
            })
    }
    chooseImg = (e,keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {};
        this.Gallery.show(imgs => {
            switch (name) {
                case 'logo':
                    formData[name] = imgs[0];
                    break;
                case 'imgs':
                    formData['imgs'] = formData['imgs'] || []
                    formData['imgs'] = formData['imgs'].concat(imgs);
                    if(formData['imgs'].length > 10){
                        formData['imgs'] = formData['imgs'].slice(0,10)
                    }
                    break;
                case 'base_certificate_info':
                    formData['base_certificate_info'] = formData['base_certificate_info'] || []
                    formData['base_certificate_info'] = formData['base_certificate_info'].concat(imgs);
                    if(formData['base_certificate_info'].length > 3){
                        formData['base_certificate_info'] = formData['base_certificate_info'].slice(0,3)
                    }
                    break;


            }
            this.setState({
                formData
            })
        })
    }
    delImg = (e,keyName) => {
        let name = keyName || ``,
            index = e.index || 0,
            formData = this.state.formData || {};
        if(typeof formData[name] == 'string'){
            formData[name] = ''
        }else {
            formData[name].splice(index,1);
        }
        this.setState({
            formData
        })
    }
    handleSort = (e,keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {},
            urls = formData[name] || [],
            oldIndex = e.oldIndex,
            newIndex = e.newIndex;
        if(oldIndex == newIndex){
            return ;
        }
        let targetRow = urls.splice(oldIndex,1)[0];
        urls.splice(newIndex,0,targetRow);
        formData[name] = urls;
        this.setState({
            formData
        })
    }

    showMap = e => {
        this.Mapper.show(res => {
            let formData = this.state.formData || {};
            for(var i in res){
                formData[i] = res[i]
            }
            this.props.form.setFieldsValue({
                address: res.address || '',
            });
            this.setState({
                formData
            })
        })
    }
    handleOnLine = () => {}
    changeTime = (e, name) => {
        let formData = this.state.formData,
            value = e ? e.format('HH:mm:ss') : '';
        formData[name] = value;
        this.setState({
            formData
        })
    }
    saveData = () => {
        let sendData = {},
            formData = this.state.formData || {};
        this.props.form.validateFieldsAndScroll((err,values) => {
            if(!err){
                sendData = values;
                sendData.end_date = moment(values.end_date).format('YYYY-MM-DD HH:mm:ss')
                console.log(sendData);
                this.context.setLoading(true)
                if(this.props.match.params.id){
                    sendData.id = this.props.match.params.id
                    shopEdit(sendData)
                        .then(res => {
                            if(res.code == 1){
                                message.success('编辑成功')
                                    .then(() => {
                                        this.props.history.push({
                                            pathname:`/shop/lists`
                                        })
                                    })
                            }
                        })
                        .finally(() => {
                            this.context.setLoading(false)
                        })

                }else{
                    shopCreate(sendData)
                        .then(res => {
                            if(res.code == 1){
                                message.success('商家创建成功')
                                    .then(() => {
                                        this.props.history.push({
                                            pathname:`/shop/lists`
                                        })
                                    })
                            }
                        })
                        .finally(() => {
                            this.context.setLoading(false)
                        })
                }
            }
        })
    }


    render() {
        let data = this.state.formData || {}
        const {getFieldDecorator, getFieldValue} = this.props.form
        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 10
            }
        }
        return (
            <div>
                <Gallery onRef={e => {this.Gallery = e}}></Gallery>
                <Mapper onRef={e => { this.Mapper = e }} lat={data.lat || ''} lng={data.lng || ''} address={data.address || ''}></Mapper>
                <Form {...formItemLayout} className={'model-form'}>
                    <div className={`msg-title mgb15`}>商家基本信息</div>
                    <FormItem label={'商家名称'}>
                        {getFieldDecorator('name', {
                            initialValue: data && data.name,
                            rules: [
                                {
                                    required: true,
                                    message: '请输入名称'
                                }
                            ]
                        })(<Input
                            placeholder={'请输入名称，限30字符'}
                            maxLength={30}
                            suffix={<span>{data.name && data.name.length ? data.name.length : 0}/30</span>}
                        />)}
                    </FormItem>
                    <FormItem label={'登录手机号'}>
                        {getFieldDecorator('mobile', {
                            initialValue: data && data.mobile,
                            rules: [
                                {
                                    required: true,
                                    message: '请输入电话'
                                }
                            ]
                        })(<Input placeholder={'请输入手机号'} maxLength={30} />)}
                    </FormItem>
                    <FormItem label={`${ data.id ? '修改密码' : '登录密码' }`}>
                        {getFieldDecorator('password', {
                            initialValue: data && data.password,
                            rules: [
                                {
                                    required: data.id ? false : true,
                                    message: '请输入密码'
                                }
                            ]
                        })(<Input placeholder={'请输入'} maxLength={30} />)}
                    </FormItem>
                    <FormItem label={'商家版本'}>
                        {
                            getFieldDecorator('version_id',{
                                initialValue: data.version_id || 1,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择版本'
                                    }
                                ]
                            })(
                                <Select style={{width:200}} placeholder={'请选择'}>
                                    {
                                        this.state.allVersion.map((item,index) => (
                                            <Select.Option value={item.id} key={index}>{ item.name }</Select.Option>
                                        ))
                                    }
                                </Select>
                            )
                        }
                    </FormItem>
                    <FormItem label={'过期时间'} required={true}>
                        {
                            getFieldDecorator('end_date',{
                                initialValue: data.end_date ? moment(data.end_date, 'YYYY-MM-DD HH:mm:ss') : null,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择过期时间'
                                    }
                                ]
                            })(
                                <DatePicker className={' mgr5'} showTime></DatePicker>
                            )
                        }
                    </FormItem>
                    <FormItem label={` `} colon={false}>
                        <Button type={'primary'} onClick={this.saveData}>立即保存</Button>
                    </FormItem>



                </Form>


            </div>
        )

    }
}
export default Form.create({})(withRouter(EditForm))



import React from 'react'
import Cookie from 'js-cookie'
import { Route, withRouter, Redirect } from 'react-router-dom'



const AuthRouter = ({ component:Component, ...rest }) => {
    const isLogin = Cookie.get('token') ? true : false
    return <Route {...rest} render = { props => ( isLogin ? <Component {...props} /> : <Redirect to='/login' /> ) } />
}


export default withRouter(AuthRouter);

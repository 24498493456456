import React from 'react'
import {  Icon, Dropdown, Menu, Input } from 'antd'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { setAdmin, removeAdmin } from '@/redux/actions/admin'
import { setShop, removeShop } from '@/redux/actions/shop'
import { adminDetail } from '@/api/admin'
import ChangePwd from '@/components/changePwd/changePwd'
import Cookie from 'js-cookie'
import CX from '@/utils/CX'

class Header extends React.Component{
    state = {}
    componentDidMount() {
       this.fetchData()
    }
    fetchData = () => {}
    handleLogout = () => {
        Cookie.remove('token')
        this.props.removeAdmin();
        this.props.removeShop();
        CX.closeSocket();
        this.props.history.push('/login')

    };
    handlePwd = () => {
        this.changePwd.show(res => {
            this.fetchData();
        })
    }

    render() {
        const DropdownList = (
            <Menu className="">
                {/*<Menu.Item key="role" onClick={ this.handlePwd }>*/}
                    {/*<Icon type="lock" />*/}
                    {/*修改密码*/}
                {/*</Menu.Item>*/}
                <Menu.Item key="logout" onClick={this.handleLogout}>
                    <Icon type="logout" />
                    退出登录
                </Menu.Item>
            </Menu>
        );
        return(
            <div className={`global-header`}>
                <ChangePwd
                    onRef={ e => { this.changePwd = e } }
                    admin={ this.props.admin }
                ></ChangePwd>
                <div className={`left`}></div>
                <div className={`right user-wrapper flex flex-end justify-center`}>
                    <div className={`top-search`}>
                        <Input placeholder={`搜索`} className={`top-search-input`} />
                    </div>
                    <div className='dropdown-wrap' id='dropdown-wrap' style={{cursor:'pointer'}}>
                        <Dropdown getPopupContainer={() => document.getElementById('dropdown-wrap')} overlay={DropdownList}>
                            <div style={{ fontSize:'14px'}}>
                                欢迎您，ACP超级管理员
                                <Icon style={{ fontSize:'13px',  cursor: 'pointer',marginLeft:'6px' }} type="caret-down" />
                            </div>
                        </Dropdown>
                    </div>
                </div>
            </div>
        )
    }

}
const mapStateToProps = state => state
const mapDispatchToProps = dispatch => ({
    setAdmin:data => {
        dispatch(setAdmin(data))
    },
    removeAdmin:() => {
        dispatch(removeAdmin())
    },
    setShop:data => {
        dispatch(setShop(data))
    },
    removeShop:() => {
        dispatch(removeShop())
    },
})


export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Header))


import React from 'react'
import { Form, Input, Tree, Checkbox , Button, Select, DatePicker, Modal } from 'antd'
import { withRouter } from 'react-router-dom'
import CX from '@/utils/CX'
import '@/assets/css/store/store.scss'
import Gallery from '@/components/Gallery/Gallery'
import PictureCard from '@/components/PictureCard/PictureCard'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
import Mapper from '@/components/Mapper/Mapper'
import PropTypes from "prop-types";
import { versionDetail, versionSave } from '@/api/version'
import { getAllMenu } from '@/api/menu'
import message from '@/utils/message'
const FormItem = Form.Item
const { TreeNode } = Tree
class EditForm extends React.Component {
    static contextTypes = {
        loading: PropTypes.object,
        setLoading: PropTypes.func
    }

    state = {
        formData: '',
        menus:[],
        expandedKeys:[],
        autoExpandParent:true,
        checkedKeys:[],
        halfCheckedKeys:[],
        isAll:false
    }

    componentDidMount() {
        this.props.onRef(this)
        this.fetchMenu()
            .then(() => {
                this.fetchData()
            })
    }

    fetchData = (params = {}) => {
        let sendData = {}
        if(this.props.match.params.id){
            this.context.setLoading(true)
            sendData.id = this.props.match.params.id
            versionDetail(sendData)
                .then(res => {
                    if(res.code == 1){
                        let detail = res.data.detail,
                            menus = this.state.menus,
                            halfCheckedKeys = detail.half_checked_keys || [],
                            rules = detail.rules || [],
                            checkedKeys = [];
                        checkedKeys = rules.filter(value => halfCheckedKeys.indexOf(value) == -1);

                        
                        menus.forEach((item,index) => {
                            item.subMenu && item.subMenu.forEach((sub,subIndex) => {
                                if(checkedKeys.indexOf(sub.menu_id) == -1 && !halfCheckedKeys.includes(item.menu_id)){
                                    halfCheckedKeys.push(item.menu_id)
                                }
                                sub.children && sub.children.forEach((child,childKey) => {
                                    if(checkedKeys.indexOf(child.menu_id) == -1 && !halfCheckedKeys.includes(sub.menu_id)){
                                        halfCheckedKeys.push(sub.menu_id)
                                        if(!halfCheckedKeys.includes(item.menu_id)){
                                            halfCheckedKeys.push(item.menu_id)
                                        }
                                    }
                                })
                            })
                        })
                        checkedKeys = rules.filter(value => halfCheckedKeys.indexOf(value) == -1);
                        this.setState({
                            formData:detail,
                            checkedKeys,
                            halfCheckedKeys
                        })
                    }
                })
                .finally(() => {
                    this.context.setLoading(false)
                })
        }
    }
    fetchMenu = () => {
        let sendData = {}
        this.context.setLoading(true)
        return new Promise((resolve, reject) => {
            getAllMenu(sendData)
                .then(res => {
                    if(res.code == 1){
                        let menus = res.data.list

                        menus=menus.map(item=>{
                            if(item.subMenu){
                                item.subMenu=item.subMenu.filter(sub=>!sub.hide)
                                
                                item.subMenu = item.subMenu.map(sub=>{
                                    if(sub.children){
                                        sub.children=sub.children.filter(child=>!child.hide)
                                    }
                                    return sub
                                })
                                
                            }
                            return item
                        })

                        this.setState({
                            menus
                        },() => {
                            resolve()
                        })
                    }else {
                        reject()
                    }
                })
                .finally(() => {
                    this.context.setLoading(false)
                })
        })
    }
    chooseImg = (e,keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {};
        this.Gallery.show(imgs => {
            switch (name) {
                case 'logo':
                    formData[name] = imgs[0];
                    break;
                case 'imgs':
                    formData['imgs'] = formData['imgs'] || []
                    formData['imgs'] = formData['imgs'].concat(imgs);
                    if(formData['imgs'].length > 10){
                        formData['imgs'] = formData['imgs'].slice(0,10)
                    }
                    break;
                case 'base_certificate_info':
                    formData['base_certificate_info'] = formData['base_certificate_info'] || []
                    formData['base_certificate_info'] = formData['base_certificate_info'].concat(imgs);
                    if(formData['base_certificate_info'].length > 3){
                        formData['base_certificate_info'] = formData['base_certificate_info'].slice(0,3)
                    }
                    break;


            }
            this.setState({
                formData
            })
        })
    }
    delImg = (e,keyName) => {
        let name = keyName || ``,
            index = e.index || 0,
            formData = this.state.formData || {};
        if(typeof formData[name] == 'string'){
            formData[name] = ''
        }else {
            formData[name].splice(index,1);
        }
        this.setState({
            formData
        })
    }
    handleSort = (e,keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {},
            urls = formData[name] || [],
            oldIndex = e.oldIndex,
            newIndex = e.newIndex;
        if(oldIndex == newIndex){
            return ;
        }
        let targetRow = urls.splice(oldIndex,1)[0];
        urls.splice(newIndex,0,targetRow);
        formData[name] = urls;
        this.setState({
            formData
        })
    }

    showMap = e => {
        this.Mapper.show(res => {
            let formData = this.state.formData || {};
            for(var i in res){
                formData[i] = res[i]
            }
            this.props.form.setFieldsValue({
                address: res.address || '',
            });
            this.setState({
                formData
            })
        })
    }
    handleOnLine = () => {}
    changeTime = (e, name) => {
        let formData = this.state.formData,
            value = e ? e.format('HH:mm:ss') : '';
        formData[name] = value;
        this.setState({
            formData
        })
    }
    saveData = () => {
        let sendData = {},
            formData = this.state.formData || {},
            checkedKeys = this.state.checkedKeys,
            halfCheckedKeys = this.state.halfCheckedKeys;
        this.props.form.validateFieldsAndScroll((err,values) => {
            if(!err){
                sendData = values;
                for(var i in formData){
                    if(!sendData[i]){
                        sendData[i] = this.state.formData[i];
                    }
                }
                sendData.half_checked_keys = halfCheckedKeys;
                sendData.rules = CX.diff(halfCheckedKeys,checkedKeys)
                this.context.setLoading(true)
                versionSave(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('编辑成功')
                                .then(() => {
                                    this.props.history.push({
                                        pathname:`/version/lists`
                                    })
                                })
                        }
                    })
                    .finally(() => {
                        this.context.setLoading(false)
                    })
            }
        })
    }
    onExpand = (expandedKeys) => {
        this.setState({
            expandedKeys
        })
    }
    onCheck = (checkedKeys,e) => {
        this.setState({
            checkedKeys,
            halfCheckedKeys:e.halfCheckedKeys
        })
    }
    handleAll = (e) => {
        let checkedKeys = [],
            allMenu = this.state.menus,
            val = e.target.checked;
        if(val){
           //全选
           allMenu.map((item) => {
               checkedKeys.push(item.menu_id);
               item.subMenu && item.subMenu.map((sub) => {
                   checkedKeys.push(sub.menu_id);
                   sub.children && sub.children.map((child) => {
                       checkedKeys.push(child.menu_id)
                   })
               })
           })
        }else {
            //反选
            checkedKeys = []
        }
        this.setState({
            isAll:val,
            halfCheckedKeys:[],
            checkedKeys
        })
    }



    render() {
        let data = this.state.formData || {};
        let menus = this.state.menus || [];
        const {getFieldDecorator, getFieldValue} = this.props.form
        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 10
            }
        }
        return (
            menus && menus.length
                ?
                <div>
                    <Gallery onRef={e => {this.Gallery = e}}></Gallery>
                    <Mapper onRef={e => { this.Mapper = e }} lat={data.lat || ''} lng={data.lng || ''} address={data.address || ''}></Mapper>
                    <Form {...formItemLayout} className={'model-form'}>
                        <div className={`msg-title mgb15`}>版本基本信息</div>
                        <FormItem label={'版本名称'}>
                            {getFieldDecorator('name', {
                                initialValue: data && data.name,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入名称'
                                    }
                                ]
                            })(<Input
                                placeholder={'请输入名称，限30字符'}
                                maxLength={30}
                                suffix={<span>{data.name && data.name.length ? data.name.length : 0}/30</span>}
                            />)}
                        </FormItem>
                        <FormItem label={'门店数量'}>
                            {getFieldDecorator('store_num', {
                                initialValue: data.store_num || 1,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入门店数量'
                                    }
                                ]
                            })(<Input placeholder={'请输入门店数量'} maxLength={30} />)}
                            <span className={`fi-help-text`}>最少设置一家门店</span>
                        </FormItem>
                        <FormItem label={'菜单'}>
                            <div>
                                <Tree
                                    checkable
                                    onExpand={this.onExpand}
                                    expandedKeys={this.state.expandedKeys}
                                    autoExpandParent={this.state.autoExpandParent}
                                    onCheck={this.onCheck}
                                    checkedKeys={this.state.checkedKeys}
                                    checkStrictly={false}
                                >
                                    {
                                        menus.map((item,index) => (
                                            <TreeNode title={item.title} key={item.menu_id}>
                                                {
                                                    item.subMenu && item.subMenu.map((sub,subIndex) => (
                                                        <TreeNode title={sub.title} key={sub.menu_id}>
                                                            {
                                                                sub.children && sub.children.map((child,childKey) => {
                                                                    return(
                                                                        <TreeNode title={child.title} key={child.menu_id}></TreeNode>
                                                                    )
                                                                })
                                                            }
                                                        </TreeNode>
                                                    ))
                                                }
                                            </TreeNode>
                                        ))
                                    }
                                </Tree>
                            </div>
                            <div>
                                <Checkbox checked={ this.state.isAll } onChange={this.handleAll}>全选</Checkbox>
                            </div>


                        </FormItem>
                        <FormItem label={` `} colon={false}>
                            <Button type={'primary'} onClick={this.saveData}>立即保存</Button>
                        </FormItem>
                    </Form>
                </div>
                :
                null
        )

    }
}
export default Form.create({})(withRouter(EditForm))



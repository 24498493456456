import React from 'react';
import { Provider } from 'react-redux'
import Router from '@/router/index'
import store from '@/redux/store'
import zhCN from 'antd/es/locale/zh_CN';
import 'moment/locale/zh-cn';
import history from '@/utils/history'
import { ConfigProvider } from 'antd'

import './assets/css/app.scss';

class App extends React.Component {
    render() {
        return (
            <ConfigProvider locale={zhCN}>
                <Provider store={store}>
                    <Router history={history} />
                </Provider>
            </ConfigProvider>
        );
    }
}

export default App;
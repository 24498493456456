import React from 'react'
import '@/assets/css/error/index.scss'

class ErrorPage extends React.Component{
    state = {}

    render() {
        return(
            <div className='error-bg'>
                <div className={'txt'}>抱歉，页面出错啦</div>
            </div>
        )
    }
}

export default ErrorPage

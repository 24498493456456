import * as actionTypes from '../constants/index'
const sideMenu = ( state = [], action) => {
    switch (action.type) {
        case actionTypes.SET_MENU:
            return action.data;
        default:
            return state
    }

}
export default sideMenu

import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Link} from 'react-router-dom';
import { addTag } from '@/redux/actions/tagList';
import { Menu, Icon } from 'antd'
import { ICON_FONT_URL } from '@/constants/iconFont'
const EditorIcon = Icon.createFromIconfontCN({
    scriptUrl:ICON_FONT_URL
})
const { SubMenu } = Menu

class SecondSideBar extends React.Component{
    state = {
        menuSelected:this.props.history.location.pathname
    }
    handleFilter = permission => {
        return true
    }
    renderOpenMenus = ( currentPath = '' ) => {
        let menus = this.props.sideMenu
        return menus.map((item,index) => {
            if(item.children){
                return(
                    <SubMenu key={index} title={<div className={`flex justify-start align-center`}><EditorIcon type={`icon-${item.icon}`} className={`cx-second-menu-icon`} /><span>{item.title}</span></div>}>
                        {
                            item.children.map(child => {
                                return (
                                    child.hide
                                        ?
                                        null
                                        :
                                        <Menu.Item className={`${child.path == currentPath ? 'active' : ''}`} key={`${child.path}`} >
                                            <Link to={child.path} >
                                                {child.title}
                                            </Link>
                                        </Menu.Item>
                                )
                            })
                        }
                    </SubMenu>
                )
            }
            return (
                this.handleFilter(item.permission) && (
                    <Menu.Item key={item.path} className={`${item.path == currentPath ? 'active' : ''}`}>
                        <Link to={`${item.path}`} className={`flex justify-start align-center`}>
                            <EditorIcon type={`icon-${item.icon}`} className={`cx-second-menu-icon`} />
                            <span className={``}>{item.title}</span>
                        </Link>
                    </Menu.Item>
                )
            )

        })
    }
    render() {
        let currentPath = this.props.history.location.pathname,
            menus = this.props.sideMenu;
        menus.forEach(item => {
            item.children && item.children.forEach(child => {
                let matchPath = child.path.split('/:')[0];
                if(currentPath.includes(matchPath)){
                    currentPath = child.selectedPath ? child.selectedPath : currentPath
                }

            })
        })

        return(
            <div className={`side-menu`}>
                <Menu className={`second-menu`} mode="inline" defaultOpenKeys={['0']} selectedKeys={[`${currentPath}`]}>
                    {this.renderOpenMenus(currentPath)}
                </Menu>
            </div>
        )
    }

}

const mapStateToProps = state => state
const mapDispatchToProps = dispatch => ({
    addTag: data => {
        dispatch(addTag(data));
    }
})

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(SecondSideBar))

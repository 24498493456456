import $axios from '@/axios/axios.js'

export function login(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/acp/login`,
        method:'post',
        data
    })
}

export function getCaptcha(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/captcha`,
        method:'post',
        data
    })
}

export function register(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/register`,
        method:'post',
        data
    })
}

export function sendSmsCode(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/sendCode`,
        method:'post',
        data
    })
}

export function forgetPwd(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/resetPassword`,
        method:'post',
        data
    })
}

/**
 * hide:true 隐藏一条路由，侧边菜单无法展示
 * selectedPath 当访问隐藏的路由时，侧边菜单选中的项
 * @Author: ljw
 */

const menus = [
    // {
    //     path:'/dashboard',
    //     title:'概况',
    //     icon:'dashboard',
    //     menu_id:'1',
    //     subMenu:[
    //         {
    //             path:'/dashboard',
    //             title:'运营概况',
    //             icon:'dashboard',
    //             menu_id:'1-1'
    //         }
    //     ]
    // },
    {
        path:'/shop/lists',
        title:'商家',
        icon:'store',
        menu_id:'2',
        subMenu:[
            {
                path:'',
                title:'商家管理',
                icon:'store',
                menu_id:'2-1',
                children:[
                    {
                        path:'/shop/lists',
                        title:'商家列表',
                        menu_id:'2-1-1',
                    },
                    {
                        path:'/shop/add/:id?',
                        selectedPath:'/shop/lists',
                        title:'添加商家',
                        hide:true,
                        menu_id:'2-1-2',
                    },
                    {
                        path:'/shop/auth/:id?',
                        selectedPath:'/shop/lists',
                        title:'商家权限',
                        hide:true,
                        menu_id:'2-1-3',
                    }
                ]
            }

        ]
    },
    {
        path:'/version/lists',
        title:'版本',
        icon:'item',
        menu_id:'3',
        subMenu:[
            {
                path:'',
                title:'版本管理',
                icon:'item',
                menu_id:'3-1',
                children:[
                    {
                        path:'/version/lists',
                        title:'版本列表',
                        menu_id:'3-1-1',
                    },
                    {
                        path:'/version/add/:id?',
                        selectedPath:'/version/lists',
                        title:'添加版本',
                        hide:true,
                        menu_id:'3-1-2',
                    }
                ]
            }

        ],
    },
    {
        path:'/miniProgram/lists',
        title:'小程序',
        icon:'item',
        menu_id:'4',
        subMenu:[
            {
                path:'',
                title:'商家小程序',
                icon:'item',
                menu_id:'4-1',
                children:[
                    {
                        path:'/miniProgram/lists',
                        title:'小程序列表',
                        menu_id:'4-1-1',
                    }
                ]
            }

        ],
    }


]
let subMenu = [];
menus.forEach((item,index) => {
    item.subMenu && item.subMenu.forEach(child => {
        subMenu.push(child);
    })
})

export { menus, subMenu }





export const SET_BREADCRUMB = 'SET_BREADCRUMB'
export const SET_TAGS = 'SET_TAGS'
export const SET_THEME = 'SET_THEME'
export const SET_COLLAPSE = 'SET_COLLAPSE'
export const EMPTY_TAG = 'EMPTY_TAG'
export const ADD_TAG = 'ADD_TAG'
export const REMOVE_TAG = 'REMOVE_TAG'
export const SET_MENU = 'SET_MENU'
export const SET_ADMIN = 'SET_ADMIN'
export const REMOVE_ADMIN = 'REMOVE_ADMIN'
export const SET_SHOP = 'SET_SHOP'
export const REMOVE_SHOP = 'REMOVE_SHOP'

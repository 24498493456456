import * as actionTypes from '../constants/index'
const setAdmin = (data) => {
    return {
        type: actionTypes.SET_ADMIN,
        data
    }
}
const removeAdmin = () => {
    return {
        type: actionTypes.REMOVE_ADMIN
    }

}
export { setAdmin, removeAdmin }

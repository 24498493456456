import $axios from '@/axios/axios.js'

export function versionLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/acp/version`,
        method:'post',
        data
    })
}

export function versionDetail(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/acp/version/detail`,
        method:'post',
        data
    })
}

export function versionSave(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/acp/version/save`,
        method:'post',
        data
    })
}

import React from 'react'
import { Modal, Input, Button,Tree, Spin, Pagination } from 'antd'
import Cookie from 'js-cookie'
import { imgLists, delImg, uploadImg, addCategory } from '@/api/gallery'
import AddCategory from './components/addCategory'
import message from '@/utils/message'
import './Gallery.scss'
const { TreeNode } = Tree

export default class Gallery extends React.Component{
    state = {
        imgLists:[],
        categoryLists:[],
        selectImgIds:[],
        isLoading:false,
        noData:false,
        title:'图片素材',
        visible:false,
        cateVisible:false,
        modalLoading:false,
        cateModalLoading:false,
        expandedKeys:[],
        category_id:0,
        name:'',
        page:1,
        total:0,
        per_page:0,
        uniqueId:""+new Date().getMilliseconds() + Math.round(Math.random()*100),
        callback: null
    }
    componentDidMount() {
        this.props.onRef(this)

    }
    show = (callback) => {
        this.setState({
            visible:true,
            selectImgIds:[],
            callback : callback ? callback : null
        })
        this.fetchData();
    }
    close = () => {
        this.setState({
            visible:false
        })
    }
    fetchData(){
        let sendData = {
            page:this.state.page,
            category_id:this.state.category_id,
            name:this.state.name
        }
        if(this.state.isLoading){
            return ;
        }
        this.setState({
            isLoading:true
        })
        imgLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let imgLists = res.data.file_list.data,
                        categoryLists = res.data.cate_list,
                        img_count = res.data.img_count,
                        per_page = res.data.file_list.per_page,
                        total = res.data.file_list.total;
                    let cate = {
                            children: [],
                            id: 0,
                            img_count: img_count,
                            name: "全部分类",
                            pid: 0
                        },
                        allCate = [];
                    cate.children = categoryLists;
                    allCate.push(cate);
                    this.setState({
                        categoryLists:allCate,
                        expandedKeys:['0'],
                        noData: imgLists.length ? false : true,
                        imgLists, total, per_page
                    })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })

    }
    tapImg = img => {
        let selectImgIds = this.state.selectImgIds;
        if(selectImgIds.includes(img.id)){
            selectImgIds.forEach((item,index) => {
                if(item == img.id){
                    selectImgIds.splice(index,1)
                }
            })
        }else {
            selectImgIds.push(img.id)
        }
        this.setState({
            selectImgIds
        })
    }
    deleteImg = (img,e) => {
        e && e.stopPropagation();
        e && e.nativeEvent.stopImmediatePropagation();
        let that = this,
            ids = [],
            sendData = {},
            selectImgIds = this.state.selectImgIds;
        ids.push(img.id);
        sendData.id = ids;
        if(selectImgIds.includes(img.id)){
            selectImgIds.forEach((item,index) => {
                if(item == img.id){
                    selectImgIds.splice(index,1)
                }
            })
        }
        Modal.confirm({
            title:'确定要删除这张图片吗？',
            onOk(){
                that.setState({
                    isLoading:true
                })
                delImg(sendData)
                    .then(res => {
                        if(res.code == 1){
                            that.setState({
                                selectImgIds
                            })
                            message.success(res.msg)
                                .then(res => {
                                    that.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        that.setState({
                            isLoading:false
                        })
                    })
            }
        })
        return ;
    }
    patchDelete = () => {
        let that = this,
            selectImgIds = this.state.selectImgIds;
        if(!selectImgIds.length){
            message.warning('请选择要删除的图片');
            return ;
        }
        let sendData = {};
        sendData.id = selectImgIds;
        Modal.confirm({
            title:`确定要删除${selectImgIds.length > 1 ? '这些' : '这张'}图片吗？`,
            onOk(){
                that.setState({
                    isLoading:true
                })
                delImg(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success(res.msg)
                                .then(res => {
                                    that.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        that.setState({
                            isLoading:false
                        })
                    })
            }
        })
    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    getInputValue = e => {
        this.setState({
            name:e.target.value
        })
    }
    handleSelect = (selectedKeys,info) => {
        let expandedKeys = this.state.expandedKeys;
        if(selectedKeys.length){
            if(!expandedKeys.includes(selectedKeys[0])){
                this.setState({
                    expandedKeys:expandedKeys.concat(selectedKeys)
                })
            }
            this.setState({
                category_id:Number(selectedKeys[0]),
                page:1,
            },() => {
                this.fetchData();
            })

        }

    }
    handleExpand = expandedKeys => {
        this.setState({
            expandedKeys:expandedKeys
        })
    }
    renderTreeNodes = data => data.map(item => {
        if (item.children && item.children.length) {
            return (
                <TreeNode title={<div><span>{item.name}</span><span className={'sub-title'}>({item.img_count})</span></div>} key={item.id} dataRef={item}>
                    {this.renderTreeNodes(item.children)}
                </TreeNode>
            );
        }
        return <TreeNode key={item.id} title={<div><span>{item.name}</span><span className={'sub-title'}>({item.img_count})</span></div>} />;
    });
    renderImgLists = imgs => imgs.map(item => {
        return (
            <div className={'img-item'} key={item.id}>
                <div className={`img-wrap ${this.state.selectImgIds.includes(item.id) ? 'selected' : ''}`} onClick={() => this.tapImg(item)} >
                    <i></i>
                    <img src={item.url} alt=""/>
                    <div className={'img-meta'}>
                        <div onClick={(e) => {this.deleteImg(item,e)}}>删除</div>
                    </div>
                </div>
                <div className={'imgSize'}>{item.width}x{item.height}px</div>
                <div className={'img-title'}>
                    {item.source_name}
                </div>
            </div>
        )
    })
    uploadChange = e => {
        var files  = document.getElementById("file"+this.state.uniqueId).files;
        files = Array.from(files);
        let fd = new FormData()
        files.forEach(item => {
            fd.append('Filedata[]',item);
        })
        fd.append('cate_id',this.state.category_id)
        fd.append('api_token',Cookie.get('token') || ``)
        this.setState({
            isLoading:true
        })
        uploadImg(fd)
            .then(res => {
                if(res.code == 1){
                    message.success(res.msg)
                        .then(res => {
                            this.setState({
                                page:1
                            },() => {
                                this.fetchData()
                            })
                        })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })

    }
    addCate = () => {
        this.setState({
            currentRow:null,
            cateVisible:true
        })
    }

    handleOk = () => {
        let imgs = [],
            imgLists = this.state.imgLists,
            selectImgIds = this.state.selectImgIds;
        selectImgIds.forEach(item => {
            imgLists.forEach(child => {
                if(child.id == item){
                    imgs.push(child.url)
                }
            })
        })
        let callback = this.state.callback;
        if(typeof callback == 'function'){
            callback(imgs)
        }
        this.close();


    }
    handleCancel = () => {
        this.close();
    }
    handleAddCate = () => {
        this.formRef.props.form.validateFields((err,values) => {
            if(!err){
                if(this.state.cateModalLoading){ return; }
                this.setState({
                    cateModalLoading:true
                })
                values.pid = 0;
                addCategory(values)
                    .then(res => {
                        if(res.code == 1){
                            message.success(res.msg)
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            cateModalLoading:false,
                            cateVisible:false
                        })
                    })

            }
        })

    }
    handleCateCancel = () => {
        this.setState({
            cateVisible:false
        })
    }
    render() {
        return(
            <div>
                <Modal
                    title={this.state.title}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    width={915}
                    confirmLoading={this.state.modalLoading}
                    maskClosable={false}
                    centered={true}
                >
                    <div className={'imgPicker-content'}>
                        <div className={'panel-wrap'}>
                            <div className={'panel-title clearfix'}>
                                <div className={'fl btn-group flex justify-start flex-start'}>
                                    <span style={{'position':'relative'}}>
                                        <Button type={'primary'}>上传图片</Button>
                                        <Input type="file" id={`file${this.state.uniqueId}`}  className={'file-input'} multiple="multiple" accept="image/gif, image/jpeg, image/png, image/bmp" onChange={this.uploadChange} ></Input>
                                    </span>
                                    <Button type={'default'} onClick={this.patchDelete}>删除图片</Button>
                                </div>
                                <div className={'fr btn-group'}>
                                    <Input placeholder={'输入图片名称搜索'} value={this.state.name} onChange={this.getInputValue} style={{'width':'150px'}} />
                                    <Button type={'primary'} icon={'search'} style={{'verticalAlign':'top'}} onClick={() => {this.fetchData()}}>搜索</Button>
                                </div>
                            </div>
                            <div className={'panel-ctx clearfix'}>
                                <div className={'cate-list fl'}>
                                    <div className={'cate-title'}>
                                        <div className={'title'}>分类</div>
                                        <Button type={'default'} size={'small'} onClick={this.addCate}>添加分类</Button>
                                    </div>
                                    <div className={'tree'}>
                                        <Tree
                                            showIcon={true}
                                            onSelect={this.handleSelect}
                                            onExpand={this.handleExpand}
                                            defaultExpandAll={true}
                                            expandedKeys={this.state.expandedKeys}
                                        >
                                            {this.renderTreeNodes(this.state.categoryLists)}
                                        </Tree>
                                    </div>
                                </div>
                                {
                                    this.state.imgLists.length > 0 ?
                                        <div className={'img-box fr'}>
                                            <div className={'img-list'}>
                                                {this.renderImgLists(this.state.imgLists)}
                                            </div>
                                            <div className={'pagination clearfix'}>
                                                <div className={'fr'}>
                                                    <Pagination showQuickJumper current={this.state.page} defaultCurrent={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className={'img-box fr'}>
                                            {
                                                this.state.noData ?
                                                    <div className={'noData'}>暂无图片，快去上传第一张图片吧</div>
                                                    :
                                                    ''
                                            }
                                        </div>
                                }

                                {
                                    this.state.isLoading ?
                                        <div className={'loading-wrap'}>
                                            <Spin></Spin>
                                        </div>
                                        :
                                        ''
                                }

                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal
                    title={'添加分类'}
                    visible={this.state.cateVisible}
                    onOk={this.handleAddCate}
                    onCancel={this.handleCateCancel}
                    maskClosable={false}
                    confirmLoading={this.state.cateModalLoading}
                >
                    <AddCategory
                        data={this.state.categoryLists}
                        visible={this.state.cateVisible}
                        wrappedComponentRef={form => {this.formRef = form} }
                    ></AddCategory>
                </Modal>
            </div>

        )
    }

}
